export default {
    fade: {
        name : 'fade',
        value: 'fade',
        durationInDefault: 0.3,
        durationOutDefault: 0.3
    },
    fadeLeft :{
        name: 'fadeLeft',
        value: 'fadeLeft',
        durationInDefault: 0.3,
        durationOutDefault: 0.3
    },
    fadeRight :{
        name: 'fadeRight',
        value: 'fadeRight',
        durationInDefault: 0.3,
        durationOutDefault: 0.3
    },
    fadeLeftBig :{
        name: 'fadeLeftBig',
        value: 'fadeLeftBig',
        durationInDefault: 0.3,
        durationOutDefault: 0.3
    },
    fadeRightBig :{
        name: 'fadeRightBig',
        value: 'fadeRightBig',
        durationInDefault: 0.3,
        durationOutDefault: 0.3
    },
    fadeUp :{
        name: 'fadeUp',
        value: 'fadeUp',
        durationInDefault: 0.3,
        durationOutDefault: 0.3
    },
    fadeDown :{
        name: 'fadeDown',
        value: 'fadeDown',
        durationInDefault: 0.3,
        durationOutDefault: 0.3
    },
    slideLeft :{
        name: 'slideLeft',
        value: 'slideLeft',
        durationInDefault: 0.3,
        durationOutDefault: 0.3
    },
    slideRight :{
        name: 'slideRight',
        value: 'slideRight',
        durationInDefault: 0.3,
        durationOutDefault: 0.3
    },
    slideUp :{
        name: 'slideUp',
        value: 'slideUp',
        durationInDefault: 0.3,
        durationOutDefault: 0.3
    },
    slideDown :{
        name: 'slideDown',
        value: 'slideDown',
        durationInDefault: 0.3,
        durationOutDefault: 0.3
    }
    // bounce :{
    //     name: 'bounce',
    //     value: 'bounce',
    //     durationDefault: 1
    // },
    // bounceDown :{
    //     name: 'bounceDown',
    //     value: 'bounceDown',
    //     durationDefault: 1
    // },
    // bounceLeft :{
    //     name: 'bounceLeft',
    //     value: 'bounceLeft',
    //     durationDefault: 1
    // },
    // bounceRight :{
    //     name: 'bounceRight',
    //     value: 'bounceRight',
    //     durationDefault: 1
    // },
    // bounceUp :{
    //     name: 'bounceUp',
    //     value: 'bounceUp',
    //     durationDefault: 1
    // }
}
