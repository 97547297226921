<template>
  <div>
    <h5 class="card-title mt-2">{{$t('Constructor.Animations.Animation')}}</h5>

    <b-form-group
        label-cols-sm="4"
        :label="$t('General.Base.Type')"
        class="mb-0 pt-0 pb-0"
    >
      <b-input-group size="sm">
        <b-form-select
            :options="getOptionsListAnimation()"
            v-model="animate"
        ></b-form-select>
      </b-input-group>

    </b-form-group>

    <b-form-group
        v-if="animate"
      label-cols-sm="4"
      :label="$t('Constructor.Animations.AnimationDurationIn')"
      class="mb-0 pt-0 pb-0"
    >
      <b-input-group :append="$t('General.Base.Sec')" size="sm">
        <b-form-input
            v-model="durationIn"
        >
        </b-form-input>
      </b-input-group>
    </b-form-group>

    <b-form-group
        v-if="animate"
        label-cols-sm="4"
        :label="$t('Constructor.Animations.AnimationDurationOut')"
        class="mb-0 pt-0 pb-0"
    >
      <b-input-group :append="$t('General.Base.Sec')" size="sm">
        <b-form-input
            v-model="durationOut"
        >
        </b-form-input>
      </b-input-group>
    </b-form-group>


  </div>
</template>

<script>
import AnimateHelper from "@/helpers/constructor/AnimateHelper";
import {isString} from "lodash";

export default {
  name: "AnimateWidget",
  props: ['value'],
  data: function() {
    return {
      animateName: '',
      animateDurationIn: null,
      animateDurationOut: null
    }
  },
  computed: {
    animate: {
      get: function () {
        if (!this.value || isString(this.value)) { //@TODO:  проверка строки временно, убрать после перехода
          return null;
        }
        return this.value.name
      },
      set: function (val) {
        this.animateName = val
        if (val !== null) {
          if (!this.value || !this.durationIn ) {
            this.durationIn = AnimateHelper[val].durationInDefault
          }
          if (!this.value || !this.durationOut) {
            this.durationOut = AnimateHelper[val].durationOutDefault
          }
        }
        this.changeValue()
      }
    },
    durationIn: {
      get: function () {
        if (!this.value || isString(this.value)) { //@TODO:  проверка строки временно, убрать после перехода
          return this.animateDurationIn;
        }
        return this.value.durationIn
      },
      set: function (val) {
        this.animateDurationIn = val
        this.changeValue()
      }
    },
    durationOut: {
      get: function () {
        if (!this.value || isString(this.value)) { //@TODO:  проверка строки временно, убрать после перехода
          return this.animateDurationOut;
        }
        return this.value.durationOut
      },
      set: function (val) {
        this.animateDurationOut = val
        this.changeValue()
      }
    }
  },
  methods: {
    getOptionsListAnimation() {
      let data = [
        {
          text: this.$t('Constructor.Animations.WithoutAnimation'),
          value: null
        }
      ]
      for (let i in AnimateHelper) {
        data.push(
            {
              text: this.$t('Constructor.Animations.' + AnimateHelper[i].name),
              value: AnimateHelper[i].value,
            }
        )
      }
      return data
    },
    changeValue() {
      if (this.animateName === null) {
        this.$emit('change', null)
        this.$emit('input', null)
        return
      }
      let data = {
        name: this.animateName,
        durationIn: this.animateDurationIn,
        durationOut: this.animateDurationOut,
      }
      this.$emit('change', data)
      this.$emit('input', data)
    }
  },
  mounted() {
    if (!this.value || isString(this.value)) { //@TODO:  проверка строки временно, убрать после перехода
      return;
    }
    this.animateName = this.value.name
    this.animateDurationIn = this.value.durationIn
    this.animateDurationOut = this.value.durationOut
  }
}
</script>

<style scoped>

</style>