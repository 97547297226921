<template>
  <div>
    <p class="card-text mb-0">{{$t('Constructor.PropWidgets.ToggleShowWidget.GuidanceElement')}}</p>
    <b-list-group >
<!--      <b-list-group-item-->
<!--          class="p-1 border-0"-->
<!--          @click="dataObj.data.type = 'target'"-->
<!--      >-->
<!--        <b-form-radio-->
<!--            name="ToggleShowWidget"-->
<!--            size="sm"-->
<!--            v-model="dataObj.data.type"-->
<!--            value="target"-->
<!--        >-->
<!--          <p class="mb-0">Селектор место вывода виджета на сайте</p>-->
<!--          <small class="text-muted">{{elem.main.target.cssSelector}}</small>-->
<!--        </b-form-radio>-->
<!--      </b-list-group-item>-->

      <b-list-group-item
          v-if="issetWidgetTooltipPos"
          class="p-1 border-0"
          @click="dataObj.data.type = 'tooltipPosition'"
      >
        <b-form-radio
            name="ToggleShowWidget"
            size="sm"
            v-model="dataObj.data.type"
            value="tooltipPosition"
        >
          <p class="mb-0">{{$t('Constructor.PropWidgets.ToggleShowWidget.TooltipHintSelector')}}</p>
          <small class="text-muted">{{issetWidgetTooltipPos}}</small>
        </b-form-radio>
      </b-list-group-item>

      <b-list-group-item
          class="p-1 border-0"
          @click="dataObj.data.type = 'manual'"
      >
        <b-form-radio
            name="ToggleShowWidget"
            size="sm"
            v-model="dataObj.data.type"
            value="manual"
        >{{$t('Constructor.PropWidgets.ToggleShowWidget.YourOwnVersion')}}</b-form-radio>
      </b-list-group-item>
    </b-list-group>

    <b-form-group
        v-if="dataObj.data.type === 'manual'"
        label-cols-sm="4"
        :label="$t('Constructor.PropWidgets.ToggleShowWidget.CssSelector')"
        label-for="main-target-css"
        class="mt-2"
    >

      <b-input-group >
        <b-form-textarea
            v-model="dataObj.data.css_selector"
            :placeholder="$t('General.Form.NoSet')"
            class="propField"
        ></b-form-textarea>
      </b-input-group>

    </b-form-group>

    <AnimateWidget v-model="dataObj.data.animate" />

    <b-form-group
        class="mt-2"
    >
      <b-form-checkbox
          v-model="visionDefault"
          class="mt-2 mousetrap"
          v-b-tooltip.hover.ds1000.dh0
          size="sm"
      >{{$t('Constructor.PropWidgets.ToggleShowWidget.HiddenDefault')}}</b-form-checkbox>

      <b-form-text>{{$t('Constructor.PropWidgets.ToggleShowWidget.NotShowOnStart')}}</b-form-text>
    </b-form-group>
  </div>
</template>

<script>
import AnimateWidget from "./../../helpers/AnimateWidget";
import HistoryHelper from "@/helpers/constructor/HistoryHelper";

export default {
  name: "ToggleShowWidget",
  components: { AnimateWidget },
  props: ["tagData", "settingConstructor", "newAction", "dataObj", "actionIndex", "eventName"],
  data() {
    return {
      dataPattern: {
        type: 'manual',
        css_selector: null,
        animate: null
      }
    }
  },
  created() {
    if (this.dataObj.data.type === undefined) {
      this.dataObj.data = this.dataPattern
    }
  },
  computed: {
    elem: function() {
      return this.tagData.htmlElements[this.settingConstructor.drags.activeElem]
    },
    issetWidgetTooltipPos: function() {
      if (this.eventName !== 'init') {
        return false
      }

      let css_selector = null
      for (let event of this.elem.js.events) { //@TODO: Переделать в перспективе, не надежно. Если будет эвент не init или действия будут в разных евентах то все сломается. На бэке такая же туду
        if (event.event === "init") {
          for (let action of event.actions) {
            if (action.type === 'tooltipPosition') {
              css_selector = action.data.css_selector
              break
            }
          }
          break
        }
      }

      if (css_selector) {
        return css_selector
      }
      return false
    },
    visionDefault: {
      get: function() {
        return this.elem.main.hiddenElem
      },
      set: function(val) {
        this.HistorySet(this.elem.main, 'hiddenElem', val)
      }
    }
  },
  methods: {
    ...HistoryHelper,
  }
}
</script>

<style scoped>

</style>
